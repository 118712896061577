@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;

button.btn_primary,
a.btn_primary {
  background-color: variable.$darkBlue-color;
  color: variable.$third-color;
  border-radius: 3px;
  padding: 8px 20px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  min-width: 120px;
  transition: all 0.5s ease-in-out;

  p{
    color: variable.$white-color;
  }

  &:hover {
    background-color: variable.$green-darker-color;
  }
}
button.btn_secondary,
a.btn_secondary {
  background-color: variable.$white-gray-color;
  color: variable.$third-color;
  border-radius: 3px;
  padding: 13px 33px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  min-width: 134px;
  transition: all 0.5s ease-in-out;

  p{
    color: variable.$third-color;
  }

  &:hover {
    background-color: variable.$gray-med-color-opacity;
  }
}
button.btn_transparent,
a.btn_transparent {
  background-color: transparent;
  border-radius: 3px;
  padding: 0;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
  transition: all 0.5s ease-in-out;

  p {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    background-color: transparent;

    p::after {
      background-color: currentColor;
    }
  }
}

button.btn_transparent2,
a.btn_transparent2 {
  color:red !important;
  background-color: transparent;
  border-radius: 3px;
  padding: 0;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;

  p {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: transparent;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    background-color: transparent;

    p::after {
      background-color: currentColor;
    }
  }
}
button.btn_navyBlue,
a.btn_navyBlue,
button.btn_navyBlue2,
a.btn_navyBlue2 {
  background-color: variable.$navyBlue-color;
  color: variable.$white-color;
  border-radius: 3px;
  letter-spacing: -0.01em;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;

  p {
    color: variable.$white-color;
  }

  &:hover {
    background-color: #072339;
  }
}
button.btn_navyBlue,
a.btn_navyBlue {
  padding: 25px 38px;
}

button.btn_navyBlue2,
a.btn_navyBlue2 {
  padding: 10px 30px;
  border: 0;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;

  p {
    color: variable.$white-color;
  }

  &:hover {
    background-color: #03223c;
  }
}
button.btn_navyBlue[disabled]:hover {
  background-color: variable.$green-darker-color;
}

button.btn_green,
a.btn_green {
  background-color: variable.$green-color;
  color: variable.$white-color;
  border: 2px solid variable.$green-color !important;

  border-radius: 3px;
  letter-spacing: -0.01em;
  padding: 7px 50px;

  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
  p {
    color: variable.$white-color;
  }
  &:hover {
    background-color: #005637;
  }
}

button.btn_gray_border,
a.btn_gray_border {
  background-color: transparent;
  color: variable.$black-light-color;
  border: 2px solid variable.$gray-light-color !important;
  border-radius: 3px;

  letter-spacing: -0.01em;
  padding: 7px 50px;

  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
  p {
    color: variable.$green-color;
  }
}
button.btn_transparent.color_light,
a.btn_transparent.color_light {
  color: variable.$navyBlue-color;

  p,
  div {
    color: variable.$navyBlue-color;
  }
}

button.btn_transparent2.color_assets,
a.btn_transparent2.color_assets {
  color: variable.$navyBlue-light-color;
  p,
  div {
    color: variable.$navyBlue-light-color;
  }
}

button.btn_transparent.color_dark,
a.btn_transparent.color_dark {
  color: variable.$navyBlue-light-color;
  p,
  div {
    color: variable.$navyBlue-light-color;
  }
}

button.btn_transparent.color_dark,
a.btn_transparent.color_dark[disabled] {
  color: variable.$navyBlue-light-color;
  &:hover {
    background-color: transparent;
  }
  p,
  div {
    color: variable.$navyBlue-light-color;
  }
}
button.btn_transparent.color_danger,
a.btn_transparent.color_danger {
  color: variable.$danger-color;
  p,
  div {
    color: variable.$danger-color;
  }
}
a.btn_primary {
  padding: 9px 35px;
}
.pointerNone {
  pointer-events: none;
}

button.btn_gray {
  background-color: variable.$white-color;
  color: variable.$black-light-color;
  border-radius: 3px;
  border: 2px solid variable.$gray-light-color;
  padding: 12px 22px;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  //opacity: 0.4;
  transition: all 0.5s ease-in-out;

  &:hover {
    background-color: #efefef;
    //opacity: 0.4;
    //opacity: 0.7;
    border: 2px solid #efefef;
  }
}

.border_gray {
  border: 2px solid variable.$gray-light-color !important;
  border-radius: 5px !important;
}

button.btn_primary:disabled {
  opacity: 0.5;
}

button.btn_primary[disabled] {
  &:hover {
    background-color: variable.$green-darker-color;
  }
}

.flex_row_reverse {
  flex-direction: row-reverse;
}

.w_100 {
  width: 100%;
}
.mt7 {
  margin-top: 7px !important;
}
.center {
  display: flex;
  align-items: center;
}
.pointerNone {
  pointer-events: none;
  cursor: not-allowed !important;
}

@include mixin.smallDesk {
  button.btn_navyBlue,
  a.btn_navyBlue {
    padding: 25px 30px;
  }
  .a.btn_primary,
  button.btn_primary {
    padding: 10px 22px;
    min-width: 115px;

    img {
      max-height: 22px;
    }
  }
  .a.btn_primary,
  button.btn_gray {
    padding: 8px 10px;
    min-width: 115px;
  }
}
