@use "../../../assets/styles/landing/variables" as variable;
@use "../../../assets/styles/landing/mixin" as mixin;

.content {
  // height: 170px;
  width: 100%;
  background-color: variable.$darkBlue-color;
  padding: 50px 60px 10px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.socialLink{
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #6AEFAB;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;

  img{
    max-height: 20px;
    max-width: 20px;
    // position: absolute;
    // bottom: 0;
  }
}

.content.content_rtl{
  > div div:nth-child(3) p{
    display: flex;
    justify-content: flex-end; 
  }
}

@include mixin.mobile {
  .content{
    padding: 20px 60px 0px 62px;

    >div{
      grid-template-columns: repeat(1, 1fr);

      > div{
        text-align: center;
        display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
      }
      div{ 
        p~div{
          margin-top: 20px;
          margin-left: 12px;
        }
      }
    }
    > div:not(:first-of-type){
      margin: 25px 0px 15px 0;

      p{
        font-size: 12px;
      }
    }
  }
}