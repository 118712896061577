@use "../../../assets/styles/landing/variables" as variable;
@use "../../../assets/styles/landing/mixin" as mixin;

.navbar {
  height: 90px;
  width: 100%;
  // width: calc(100% - 78px);
  // margin-right: calc(100% - 78px);
  background-color: variable.$gray-lighter-color; 
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  //position: absolute;
  //if we want it to be fixed without animation
  position: fixed;
  left: 0;
  z-index: 41;
  //transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 12px 2px rgb(0 0 0 / 15%);
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  .menuWrapper{
    display: none;
  }
  .hiddenMobile{
    display: block;
  }

  &.sidebarOpen{
    width: calc(100% - 350px);
  margin-right: calc(100% - 350px) ;
  }
  .logo{
    width: 100px;
  }

  .dropdownWrap{
    
    > div > div{
      display: flex;
      flex-wrap: wrap;
      max-width: 350px;

      a{
        width: 50%;

        &:hover{
          color: black;
        }
      }
    }
  }
}

.nav_link {
  display: flex;
  border-radius: 3px; 
  font-weight: light;

  &:hover {
    transition: 0.2s ease-in-out;
    //background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px; 
      background:variable.$white-color;
      
        color: variable.$third-color; 
     
      svg {
        fill: variable.$third-color;
    
        path, polygon, circle {
          fill: variable.$third-color;
        }
      }
    
  }
  svg {
    fill: variable.$white-color;

    path, polygon, circle {
      fill: variable.$white-color;
    }
  }
}
.nav_link.active {
  box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05),
    inset 0px 1px 1px variable.$white-color;
  background-color: variable.$white-color;

  p {
    color: variable.$third-color;
  }

  svg {
    fill: variable.$third-color;

    path, polygon, circle {
      fill: variable.$third-color;
    }
  }
}
 
  .navbar.navbar_rtl{ 
    img{
      margin-left: 5px;
      margin-right: 0px;
    }
  } 

@include mixin.smallDesk {
  .navbar {
    height: 65px;
    // width: calc(100% - 73px);
    // margin-right: calc(100% - 75px);

    div:first-of-type img[alt="logo"] {
      width: 60px;
    }

    &.sidebarOpen{
      width: calc(100% - 300px);
    margin-right: calc(100% - 300px) ;
    }
    .logo{
      width: 65px;
    }
  }
}
/* @include mixin.smallDesk {
  .navbar {
    padding: 10px 50px 10px 55px;
  }
} */
@include mixin.mobile {
  .navbar.sidebarOpen{
    width: calc(100% - 73px);
    margin-right: calc(100% - 75px);
  }

  .navbar{
    .menuWrapper{
      display: block;
    }
    .menu{ 
      background-color: #f9f9f9;
      position: fixed;
      top: 65px;
      left: 0;
      right: 0;
      // bottom: 0;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      box-shadow: 3px 5px 5px rgba(0,0,0,.2);
    }
    .hiddenMobile{
      display: none;
    }
  }  
}