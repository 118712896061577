@use "../../../assets/styles/landing/variables" as variable;
@use "../../../assets/styles/landing/mixin" as mixin;

.catLink{ 
  font-size: 18px;
  color: variable.$darkBlue-color;
  text-align: center;
}
.category{
  width: 100%;
  min-height: 320px; 
  background: variable.$white-color;
  border-radius: 25px;
  display: flex;
  align-items: flex-start;
  // justify-content: center;
  padding: 40px 60px;
  margin-bottom: 30px;

  > div{
    width: 280px;
    height: 100%;
  }

  img{
    max-width: 100%;
    height: 100%;
    max-height: 320px;
    object-fit: cover;
    border-radius: 25px;
  }
  img.img1{
    display: none;
  }
}
 
  

.w_100 {
  width: 100%;
}  

@include mixin.smallDesk {
   
}
@include mixin.mobile {
  .category{ 
    min-height: 290px; 
    border-radius: 15px; 
    align-items: center;
    justify-content: center;
    padding: 15px 30px;
    margin-bottom: 20px;

    > div{
      margin: 0 !important;
    }

    div{
      text-align: center;

      .title{
        font-size: 21px;
      }
      .grid{
        grid-template-columns: repeat(3, 1fr);
        margin: 20px 0 5px 0;
        
      }
    }
    img{ 
      height: 140px; 
      border-radius: 15px;
      display: none;
      padding: 0;

      &.img1 {
        display: flex !important;
        margin: 0 auto;
      }
    }
    .img1 {
      display: block !important;
    }
     
  }
  .catLink{ 
    font-size: 15px; 
  }
}