@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;
 
.wrapper {
  //width: 360px;
  width: 80px;
  height: 100vh;
  //background-color: variable.$black-color;
  //padding: 50px 40px;
  //return posotion to fixed to return as old
  position: relative;
  top: 0px;
  z-index: 50;
  overflow-x: hidden;
  // overflow-y: auto;
  overflow-y: hidden;
  cursor: pointer;
  border-right: 2px solid variable.$gray-light-color;

  //scrollbar style
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgb(226, 226, 226);
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(226, 226, 226);
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      color: variable.$white-color;
      padding: 10px; 
      border-bottom: 2px solid rgb(218, 218, 218);
    }
  }

  .list_items {
    display: flex;
    align-items: center;
    &:hover {
      border-radius: 5px !important;

      /* p {  
        color: variable.$third-color; 
      } */
    }
    p {
      width: 230px !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: variable.$white-color;
      margin-left: 20px;
      margin-right: 20px;
      text-align: left;
    }

    &:first-of-type {
      border-radius: 3px;
    }
  }

  .accord_item {
    border: none;

    h2 {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      border: none; 

      button:hover{
        border: 2px solid variable.$white-color;
        /* background:variable.$white-color;
        
        div{
          color: variable.$third-color; 
        }
        svg {
          fill: variable.$third-color;
      
          path, polygon, circle {
            fill: variable.$third-color;
          }
        } */
      }

      div {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.01em;
        color: variable.$white-color; 
        text-align: left;
        svg {
          fill: variable.$white-color;
      
          path, polygon, circle {
            fill: variable.$white-color;
          }
        }
      }
      svg {
        fill: variable.$white-color;
    
        path, polygon, circle {
          fill: variable.$white-color;
        }
      }
    }

    a.nav_link p {
      text-align: left;
      color: variable.$white-color;
    }
    a.nav_link:hover {
      transition: 0.2s ease-in-out;
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 3px;
    }

    a.nav_link.active {
      box-shadow: none;
      //background-color: variable.$gray-med-color-opacity;
      background-color: unset;
      p {
        color: #909090;
        // color: variable.$white-color;
      }
    }
  }

  .centerBurger {
    //padding-left: 15px;
    transition: all 0.5s ease-in-out;
  }

  .content {
    position: relative;
    background-color: transparent;
    height: calc(100% - 80px);
  }

  .dropdown_item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: variable.$gray-font-color;
    padding: 9px 30px;
    text-align: left;
  }

  &::before{
    position: absolute;
    bottom: -24%;
    left: -75%;
    right: 0;
    width: 175%;
    content: "";
    background-color: #1da25d;
    height: 300px;
    z-index: 5;
    box-shadow: 5px 5px 5px rgba(0,0,0,.5);
    transform: rotate(197deg);
  }
  transition: width 0.8s ease-in-out;
}

.nav_link {
  display: flex;
  border-radius: 3px; 
  border: 2px solid variable.$third-color;
  &:hover {
    transition: 0.2s ease-in-out;
    //background-color: rgba(0, 0, 0, 0.04);
    border-radius: 3px; 
    border: 2px solid variable.$white-color;
      /* background:variable.$white-color;
      
        color: variable.$third-color; 
     
      svg {
        fill: variable.$third-color;
    
        path, polygon, circle {
          fill: variable.$third-color;
        }
      } */
    
  }
  svg {
    fill: variable.$white-color;

    path, polygon, circle {
      fill: variable.$white-color;
    }
  }
}
.nav_link.active {
  // box-shadow: inset 0px -2px 1px rgba(0, 0, 0, 0.05),
  //   inset 0px 1px 1px variable.$white-color;
  // background-color: variable.$white-color;
  border: 2px solid variable.$white-color;

  // p {
  //   color: variable.$third-color;
  // }

  // svg {
  //   fill: variable.$third-color;

  //   path, polygon, circle {
  //     fill: variable.$third-color;
  //   }
  // }
}

.btn_burger_wrapper {
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  width: 78px;
  //border-bottom: 2px solid variable.$gray-light-color;
  //border-right: 2px solid variable.$gray-light-color;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: variable.$third-color;
  border-bottom-right-radius: 5px;
  z-index: 4;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  .content {
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    padding: 0;
    background: 0;
    border: 0;
    outline: 0;
    border-radius: 0;
    transform: scale(1.7);
    cursor: pointer;

    .topWrapper,
  .bottomWrapper,
  .centerWrapper {
    top: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    transform-origin: center;
  }
  
  .topBread,
  .bottomBread,
  .centerBread {
    height: 1.5px;
    background: variable.$white-color;
    width: 1rem;
    right: 0.25rem;
    position: absolute;
    transform-origin: center;
  }

  .bottomBread {
    // transition: all .4s ease-in-out;
  }
  
  .topBread {
    top: 6px;
  }
  
  .bottomBread {
    /* height: 1.3px;
    bottom: 6px; */
    height: 0.8px;
    bottom: 7px;
  }
  
  .centerBread {
    height: 1.3px;
    top: 11px;
  }
  }
  
  

  svg{
    path, polygon, circle{
      fill: variable.$white-color
    }
  }

  /* &::before {
    content: "";
    position: fixed;
    height: 85px;
    width: calc(360px - 80px - 8px);
    background-color: variable.$gray-lighter-color;
    z-index: -1;
    left: 80px;
    top: 0;
    transition: all 1s both cubic-bezier(0.075, 0.82, 0.165, 1);
  } */
}

.open {
  padding-left: 15px !important;
  padding-right: 15px !important;
  animation: fade-right 0.4s both cubic-bezier(0.075, 0.82, 0.165, 1);

  .bottomBread {
    /* height: 1.3px;
    bottom: 6px; */
    // height: 0.8px;
    height: 0px !important;
  }

  .centerBurger {
    //padding-left: 10px;
    justify-content: flex-start;
  }

  .btn_burger_wrapper {
    // width: 352px;
    // justify-content: flex-start;
    // padding-left: ;
     &::before {
      content: "";
      position: fixed;
      height: 70px;
      background-color: variable.$third-color;
      z-index: -1;
      left:76px;
      top: 0;
      width: calc(360px - 80px - 8px);
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    } 
  }
}
.closed {
  padding-left: 15px !important;
  padding-right: 15px !important;

  animation: fade-left 0.4s both cubic-bezier(0.075, 0.82, 0.165, 1);

  .btn_burger_wrapper {
    &::before {
      content: "";
      position: fixed;
      height: 70px;
      background-color: variable.$third-color;
      z-index: -1;
      left: 76px;
      top: 0;
      width: 0px;
      transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .list_items {
    justify-content: center;
    p {
      text-align: left;
      display: none;
    }
  }
  button {
    display: flex;
    justify-content: center;

    /* div:last-of-type {
      display: none;
    } */
    div ~ svg {
      display: none;
    }
  }
  .accord_item button { 
 
    div:nth-child(2) {
      display: none;
    }
  }
  .accord_panel {
    display: none;
  }

  .nav_link {
    justify-content: center;
  }
  /* .btn_burger_wrapper {
    &::before {
      position: fixed;
      height: 85px;
      background-color: variable.$gray-lighter-color;
      z-index: -1;
      left: 80px;
      top: 0;
      width: 0;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  } */
}

// .iconPos {
//   position: absolute;
//   right: 13px;
// }

@keyframes fadeRight {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
}

@keyframes fade-left {
  0% {
    /* -webkit-transform: translateX(360px);
    transform: translateX(360px); */
    width: 360px;
    opacity: 1;
  }
  100% {
    /* -webkit-transform: translateX(80px);
    transform: translateX(80px); */
    width: 80px;
    opacity: 1;
  }
}
@keyframes fade-right {
  0% {
    width: 80px;
    opacity: 1;
  }
  100% {
    width: 360px;
    opacity: 1;
  }
}

@include mixin.smallDesk {
  .wrapper {
    .content {
      height: calc(100% - 70px);
    }
  }
  .iconPos {
    width: 20px;
    height: 20px;
    margin-top: 3px;
  }
  .wrapper {
    width: 75px;

    .list_items {
      padding-top: 10px !important;
      padding-bottom: 10px !important;

      p {
        text-align: left;
        font-size: 14px;
        //margin-bottom: 4px;
      }
    }
    .accord_item {
      h2 {
        div {
          font-size: 14px;

           
        }

         
      }

      > div > div {
        padding-top: 0px;
        padding-bottom: 0;
      }
    }
    .dropdown_item {
      font-size: 14px;
      padding: 8px 20px 8px 28px;
    }
  }
  .closed,
  .open {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  .centerBurger {
    img {
      width: 28px;
      height: 28px;
    }
  }
  .btn_burger_wrapper {
    height: 65px;
    width: 73px;
  }
  .open {
    .btn_burger_wrapper {
      &::before {
        content: none;
      }
    }
  }
  .closed {
    .btn_burger_wrapper {
      &::before {
        content: none;
      }
    }
  }
  .content {
    margin-top: 0px !important;
    padding-top: 24px !important;
    // padding-top: 70px !important;
  }

  @keyframes fade-left {
    0% {
      width: 300px;
      opacity: 1;
    }
    100% {
      width: 75px;
      opacity: 1;
    }
  }
  @keyframes fade-right {
    0% {
      width: 75px;
      opacity: 1;
    }
    100% {
      width: 300px;
      opacity: 1;
    }
  }
}
@include mixin.mobile {
  .wrapper::before{
    display: none;
  }
  .wrapper.closed {
    width: 0 !important;
    padding: 0 !important;
    height: 65px !important;
  }
  .content div:first-of-type  .nav_link{
    margin-top: 20px;
  }

  .btn_burger_wrapper {
    width: 80px !important;
  }
  .wrapper{
    position: fixed;
  }
}