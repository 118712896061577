$primary-color: #f5f5f5;
$secondary-color: #f9f7f2;
$third-color:#002A5C;
$darkBlue-color:#022F59;
//$third-color:#0F337C;
//$third-color:#6f767e;

// $darkBlue-color: #BE927F;
$green-darker-color: #1da25d; 

$red-lighter-color: #ebcbcb;
$red-light-color: #e14642;
$red-dark-color: #e0232e;
$danger-color: #e0403f;

$black-light-color: #1d1f22;
$black-dark-color: #1a1d1f;
$black-darker-color: #13100d;

$gray-font-color: #6f767e;

$navyBlue-color: #003865;
$navyBlue-light-color: #007bb5;

$gray-lighter-color: #f9f9f9;
$gray-light-color: #dddddd;
$gray-med-color: #f3f3f3;
$gray-dark-color: #e1e1e1;
$gray-darker-color: #f9f7f2;
$gray-med-color-opacity: #efefef80;
$white-gray-color: #e0e0e0;

$semi-white-color: #fcfcfc;
$white-color: #fff;
$black-color: #000;

$green-color: #006b44;
