 

$digitWidth: 200px;
$digitHeight: 260px;

$legHeight: $digitHeight / 4;

// =========

@mixin gradient($direction) {
  background: linear-gradient($direction, rgb(106, 239, 171) 0%, rgb(106, 239, 171) 20%, rgb(45, 142, 92) 50%, rgb(20 85 51) 100%);
}

// =========

 .outer-wrapper{
     h1 {
       font-family: 'Lato', sans-serif;
       text-transform: uppercase;
       color: #022E5B;
       font-weight: 700;
       text-align: center;  
       font-size: 20px;
     }

 }


.outer-wrapper {
  padding-top: 10%;
  width: 100%;
  height: 100vh;
  
  @media (max-width: 767px) {
    padding-top: 5%;
  }
  
  .inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    white-space: nowrap;
  }
}

// =========

.four-wrapper {
  position: relative;
  width: $digitWidth;
  height: $digitHeight;
  margin: 12px;
  
  .leg-1 {
    @include gradient(to right);
    width: 210px;
    height: $legHeight;
    overflow: hidden;
    position: absolute;    
    top: 44px;
    left: 0;
    border-radius: 35px;    
    transform-origin: 50% 100%;    
    transform: rotate(-50deg);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, .1);
    z-index: 3;
  }
  
  .leg-2 {
    @include gradient(to right);
    width: 100%;
    height: $legHeight;
    overflow: hidden;
    position: absolute;    
    top: 113px;
    left: 0;
    border-radius: 35px;
    z-index: 1;
    
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;    
      background-color: rgba(0, 0, 0, 0.1);
    }
    
    &::before {
      width: 30px;
      height: 80px;
      transform: rotate(50deg);
      left: 50px;
      top: -15px;      
    }
    
    &::after {
      width: 30px;
      height: 100%;
      transform: skew(-15deg);
      left: 88px;
      top: 0;      
    }
  }
  
  .leg-3 {
    @include gradient(to top);    
    width: $legHeight;    
    height: 235px;
    overflow: hidden;
    position: absolute;    
    top: 0;
    left: 95px;
    border-radius: 35px;
    box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.1);
    z-index: 1;
    
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;    
      background-color: rgba(0, 0, 0, 0.05);
    }
    
    &::before {
      width: 30px;
      height: 100%;
      transform: rotate(25deg);       
    }
    
    &::after {
      top: -30px;
      width: 25px;
      height: 100%;
      transform: rotate(50deg);       
    }
  }
}

.zero-wrapper {
  @include gradient(45deg);
  position: relative;
  width: 235px;
  height: 235px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, .2) inset;
  margin: 12px;
  
  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
  }
    
  &::before {
    top: 40%;
    left: 33px;
    width: 104px;
    height: 60%;
    transform: rotate(35deg);  
    background-color: rgba(0, 0, 0, .05);
  }
  
  &::after {
    top: -60px;
    left: -5px;
    width: 60%;
    height: 110%;
    background-color: rgba(255, 255, 255, .1);
    transform: rotate(45deg);
  }  
  
  .zero-wrapper__middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: round(235px / 2.25);
    height: round(235px / 2.25);
    background-color: #FFF;
    border-radius: 50%;
    box-shadow: -2px 2px 5px rgba(0, 0, 0, .2);
    z-index: 2;
  }
}