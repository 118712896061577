@use "../../assets/styles/variables" as variable;
@use "../../assets/styles/mixin" as mixin;

.navbar {
  height: 70px;
  width: calc(100% - 78px);
  margin-left: calc(100% - 78px);
  background-color: variable.$gray-lighter-color; 
  padding: 10px 20px 10px 20px;
  display: flex;
  align-items: center;
  //position: absolute;
  //if we want it to be fixed without animation
  position: fixed;
  right: 0;
  z-index: 41;
  //transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 12px 2px rgb(0 0 0 / 15%);
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &.sidebarOpen{
    width: calc(100% - 350px);
  margin-left: calc(100% - 350px) ;
  }
  .logo{
    width: 85px;
  }
}

@include mixin.smallDesk {
  .navbar {
    height: 65px;
    width: calc(100% - 73px);
    margin-left: calc(100% - 75px);

    div:first-of-type img[alt="logo"] {
      width: 60px;
    }

    &.sidebarOpen{
      width: calc(100% - 300px);
    margin-left: calc(100% - 300px) ;
    }
    .logo{
      width: 65px;
    }
  }
}
/* @include mixin.smallDesk {
  .navbar {
    padding: 10px 50px 10px 55px;
  }
} */
@include mixin.mobile {
  .navbar.sidebarOpen{
    width: calc(100% - 73px);
    margin-right: calc(100% - 75px);
  }
}